<template>
  <v-row justify="center" v-if="shower === true">
    <v-dialog v-model="shower" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title>{{ $t("keydetail.details") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-information</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:400px">
          <div class="text-center">
           <v-progress-circular
              v-if="createprogress === true"
              indeterminate
              :color="color.theme"
              size="70"
              
            >
           <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-progress-circular>
          </div>
            <div>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12>
               <v-subheader>
              {{ $t("keydetail.general") }}
              <v-divider class="mx-4"></v-divider>
            </v-subheader>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs2 lg2 class="pl-4 pt-4"><p class="font-weight-bold">{{ $t("tablefile.filenameonly") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4 pt-4"><p > {{ file.filename }} </p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.docno") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4"><p >{{ file.document_no}}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.docdate") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4"><p>{{ formatdatetime(file.dtm_send) }}</p></v-flex>
            </v-layout>
             <v-layout xs12 lg12 row wrap>
                <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.doctype") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4"><p>{{ file.document_type }}</p></v-flex>
            </v-layout>
             <v-layout xs12 lg12 row wrap>
               <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.sendername_") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4"><p>{{$t('default') === 'th' ?  file.sender_name_th : file.sender_name_th}}</p></v-flex>
            </v-layout>
             <v-layout xs12 lg12 row wrap>
               <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.receivername_") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4"><p>{{$t('default') === 'th' ?  file.receiver_name_th : file.receiver_name_th}}</p></v-flex>
            </v-layout>
             <v-layout  xs12 lg12 row wrap>
                <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.outboxdate") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4"><p>{{ formatdatetime(file.last_dtm) }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs2 lg2 class="pl-4 "><p class="font-weight-bold">{{ $t("tablefile.status") }} : </p></v-flex>
              <v-flex xs10 lg10 class="pl-4 "><p>{{file.status_send}}</p></v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" :dark="color.darkTheme" @click="$emit('closedkeydetail')">{{ $t("keydetail.closeDialog") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {

  props: ["show", "file"],
  data: function() {
    return {
      // loaddataprogress:false,
      opendialogeditdeletehashtag:false,
      filename: "",
      createprogress: false,
      dataObj: {},
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("filedata",this.file);
        }
        return this.show;
      },
    },
    colorProgress(){
      return 'color:'+this.color.theme+';'
    },
  },
  methods: {
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
  },
};
</script>
<style></style>
